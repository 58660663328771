import request from './../utils/request'

// message list
export function getSystemMessageList(data) {
  return request({
    url: 'audio/user/systemMessage',
    method: 'get',
    params: data
  })
}

// 获取未读消息数（小红点）
export function getSystemMessageUnreadCount(data) {
  return request({
    url: 'audio/user/systemMessageUnreadCount',
    method: 'get',
    params: data
  })
}

// 删除消息
export function deleteSystemMessage(data) {
  return request({
    url: 'audio/user/systemMessageDelete',
    method: 'post',
    params: data
  })
}
