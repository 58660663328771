import Vue from 'vue'
import Confirm from './Confirm.vue'

const ConfirmBox = Vue.extend(Confirm)

Confirm.install = function (content, options) {
  let data = {
    content: content,
    cancelText: options.cancelText ? options.cancelText : '取消',
    confirmText: options.confirmText ? options.confirmText : '确定',
    cancelCallback: options.cancelCallback,
    confirmCallback: options.confirmCallback
  }
  let instance = new ConfirmBox({
    data: data
  }).$mount()

  document.body.appendChild(instance.$el)

  Vue.nextTick(() => {
    instance.visible = true
  })
}

export default Confirm
