import request from './../utils/request'

export function getQiniuVideoToken (data) {
  if (data == null) data = {}
  data.t = new Date().getTime()
  return request({
    url: '/api/cdn/moyinVideo/token',
    method: 'post',
    params: data
  })
}

export function getQiniuImageToken (data) {
  if (data == null) data = {}
  data.t = new Date().getTime()
  return request({
    url: '/api/cdn/image/token',
    method: 'post',
    params: data
  })
}

export function getQiniuAttachmentToken (data) {
  if (data == null) data = {}
  data.t = new Date().getTime()
  return request({
    url: '/api/cdn/attachment/token',
    method: 'post',
    params: data
  })
}
