import request from './../utils/request'

export function modifyUserInfo(data) {
  return request({
    url: 'audio/user/modify',
    method: 'post',
    params: data
  })
}
export function uploadImgToServer(data) {
  return request({
    url: 'api/cdn/upload/img',
    method: 'post',
    data: data
  })
}
export function bindWechat(data) {
  return request({
    url: 'audio/user/bind/wechat',
    method: 'post',
    params: data
  })
}
export function unbindWechat(data) {
  return request({
    url: 'audio/user/unbind/wechat',
    method: 'post',
    params: data
  })
}

export function getMyCapacity(data) {
  return request({
    url: 'audio/user/my/capacity',
    method: 'post',
    params: data
  })
}

export function getUserDetail(data) {
  return request({
    url: 'audio/user/detail',
    method: 'get',
    params: data
  })
}
