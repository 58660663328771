import request from './../utils/request'

export function sendSmsCode (data) {
  return request({
    url: 'anon/audio/user/send/sms/code',
    method: 'post',
    params: data
  })
}
export function userLogin (data) {
  return request({
    url: 'anon/audio/user/login',
    method: 'post',
    params: data
  })
}

export function loginByWechat (data) {
  if (data == null) data = {}
  data.t = new Date().getTime()
  return request({
    url: 'anon/audio/user/loginByWechat',
    method: 'post',
    params: data
  })
}

export function bindWechat (data) {
  if (data == null) data = {}
  data.t = new Date().getTime()
  return request({
    url: 'audio/user/bind/wechat',
    method: 'post',
    params: data
  })
}

export function unbindWechat (data) {
  if (data == null) data = {}
  data.t = new Date().getTime()
  return request({
    url: 'audio/user/unbind/wechat',
    method: 'post',
    params: data
  })
}

export function bindUserInfo (data = {}) {
  return request({
    url: 'audio/user/keyInfo/update',
    method: 'post',
    params: data
  })
}

export function getWeChatUrl() {
  return request({
    url: 'anon/user/wx/getQrcode',
    method: 'get'
  })
}

export function checkUrl (url) {
  return request({
    url,
    method: 'get'
  })
}
